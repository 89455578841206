html,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDialogContent-root {
  overflow-y: unset !important;
}
.locationLabel {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.locationLabel input {
  margin: 15px 0;
}
.locationLabel input:valid + span,
.locationLabel input:focus + span {
  top: 13px;
  color: var(--theme-color);
  font-size: 10px;
}
.locationLabel input:read-only + span {
  display: none;
}
.locationLabel input:read-only::selection {
  background-color: rgb(0, 0, 0);
}

.locationLabel span {
  position: absolute;
  left: 10px;
  transition: all 0.3s;
  color: var(--gray-text);
  top: 50%;
  transform: translateY(-50%);
}
.autocomplete {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
    rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  top: 45px;
  left: -2px;
}
.autoloading {
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 0;
}
.suggestion {
  padding: 10px;
  font: inherit;
  color: var(--gray-text);
  cursor: pointer;
}
.locationtext textarea {
  max-height: 40px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  margin: 0;
}
.location-search-input {
  font-size: 1em;
  border: 0px;
  width: 100%;
  height: 30px;
}
.location-fieldset {
  border: 0;
  /* border-color: #e0e0e385;
  border-radius: 10px; */
  padding-bottom: 2px;
  padding-top: 0px;
}
.auto-complete-title {
  color: #2194f3;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75em;
}
.RaCreate-noActions-201 {
  margin-top: 0em;
}

.big-1-5x-and-fade {
  animation: 0.7s linear 0s infinite alternate big-animation,
    0.7s linear 0s infinite alternate fade-animation;
}

@keyframes big-animation {
  from {
  }
  to {
    transform: scale(1.5);
  }
}

@keyframes fade-animation {
  from {
  }
  to {
    opacity: 0;
  }
}
